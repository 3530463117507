import { storeCTEvent, storeEvent }           from "_analytics/index";
import Toast                                  from "_common/toast";
import { clearUserData, storeUserDataCookie } from "_common/utils/user";
import { createAuthData }                     from "_services/api/auth";
import {
	loginApi, loginWithGoogleApi, logoutApi
} from "_services/api/auth";
import { getUserProfile } from "_services/api/user";

let userProfileParams = {
	follow            : true,
	isFollowing       : true,
	country           : true,
	stats             : true,
	block             : true,
	gems              : true,
	teams             : false,
	sports            : false,
	pendingRewards    : false,
	access            : true,
	types             : true,
	followingGames    : true,
	onboarding        : true,
	communityTeamUser : true
};

export const getOwnProfile = async ( { userId } ) => {
	const userProfileData = await getUserProfile ( { userId }, userProfileParams );

	return userProfileData;

};

export const loginUser = async data => {
	const response        = await loginApi ( data );
	const userData        = storeUserDataCookie ( response );
	const userProfileData = await getUserProfile ( { userId: userData.userId }, userProfileParams );

	const eventAction = userData.isNew ? "Sign_Up" : "Log_In";
	const eventLabel  = userData.isNew ? "Header_SignUp" : "Header_LogIn";

	storeEvent ( "Authentication", eventAction, eventLabel );
	storeCTEvent ( {
		eventName : "user_signed_in",
		campaign  : "organic",
		is_new    : userData.isNew ? 1 : 0,
		OS        : "Web"
	} );

	return { userProfileData };
};

export const loginUserWithGoogle = async data => {
	const response        = await loginWithGoogleApi ( data );
	const userData        = storeUserDataCookie ( response );
	const userProfileData = await getUserProfile ( { userId: userData.userId }, userProfileParams );

	return { userProfileData };
};

export const logoutUser = async data => {
	try {
		if ( !data?.forceLogout ) {
			await logoutApi ();
			storeEvent ( "Authentication", "Log_out", "Header_Logout" );
			if ( !data?.deleteAccount ) {
				Toast.info ( "Successfully logged out." );
			}
		}
		clearUserData ();
		await createAuthData ();

	} catch ( error ) {
		Toast.error ( error.displayMsg );
	}
};
