import { initializeApp } from "firebase/app";
import {
	getAuth,
	signInAnonymously
}                          from "firebase/auth";
import { getFirestore }    from "firebase/firestore";
import { getRemoteConfig } from "firebase/remote-config";

import { configs } from "_config/index";

/* Initialising sportx, broadcast & backup app services */
const sportx    = initializeApp ( configs.firebase.sportx, "sportx" );
const broadcast = initializeApp ( configs.firebase.broadcast, "broadcast" );

export const firestoreConfig = {
	sportx    : getFirestore ( sportx ),
	broadcast : getFirestore ( broadcast )
};

export const getRemoteConf = () => {
	return {
		sportx: getRemoteConfig ( sportx )
	};
};

const auth = {
	broadcast: getAuth ( broadcast )
};

export const signInToBroadcastService = async () => {
	await signInAnonymously ( auth.broadcast );
};