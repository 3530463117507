import {
	createAuthToken,
	getDeviceId,
	getUserData,
	storeUserDataCookie,
	USER_LOGIN_REQUEST
} from "_common/utils/user";
import XHR                                   from "_common/xhr";
import { APP_VERSION, configs, DEVICE_TYPE } from "_config/index";

export const createAuthData = async function () {
	const tokenData = createAuthToken ();

	const result = await XHR.post.bind ( tokenData ) ( {
		url  : `${ configs.AUTH }auth/deviceLogin`,
		data : USER_LOGIN_REQUEST
	}, { noBearer: true } );

	const data = {
		accessToken  : result?.accessToken,
		refreshToken : result?.refreshToken,
		deviceId     : tokenData?.userAuth?.deviceId,
		tokenType    : result?.tokenType,
		isNew        : result?.isNew,
		locale       : result?.locale,
		userId       : result?.isVerified ? result?.userId : null,
		userRoles    : result?.userRoles
	};

	return storeUserDataCookie ( data );
};

export const loginApi = async data => {

	let loginData = {
		countryCode        : data.countryCode,
		locale             : data.locale,
		mobile             : data.mobile,
		otp                : data.otp,
		otpTxnId           : data.otpTxnId.toString (),
		pushId             : data.pushId,
		thirdPartyCampaign : "{ \"campaign\": \"\", \"source\" : \"google-play\", \"medium\" : \"organic\" }"
	};

	const response = await XHR.post.bind ( this ) ( {
		url     : `${ configs.AUTH }auth/login`,
		headers : {
			"Content-Type": "application/json"
		},
		data: loginData
	} );

	return response;
};

export const loginWithGoogleApi = async loginData => {

	const response = await XHR.post.bind ( this ) ( {
		url  : `${ configs.AUTH }auth/loginViaGoogle`,
		data : loginData
	} );

	return response;
};

/*
  Dev note: converted to normal fetch call to not invoke axios interceptor
*/
export const logoutApi = async () => {
	const refreshToken = getUserData ()?.refreshToken;
	const accessToken  = getUserData ()?.accessToken;
	const deviceId     = getDeviceId ();

	await fetch ( `${ configs.AUTH }auth/logout`, {
		method  : "POST",
		headers : {
			"Content-Type"  : "application/json",
			"device-type"   : DEVICE_TYPE,
			"app-version"   : APP_VERSION,
			"device-id"     : deviceId,
			"authorization" : `Bearer ${ accessToken }`
		},
		body: JSON.stringify ( { refreshToken } )
	} );

	return;
};

export const enableOtpInput = async ( mobile, countryCode ) => {
	const data = {
		countryCode,
		mobile,
		smsToken: "asdg1234567"
	};

	const result = await XHR.post.bind ( this ) ( {
		url: `${ configs.AUTH }auth/otp`,
		data
	} );

	if ( !result )
		return false;

	return result;
};

export const deleteAccount = async ( { userId, reason, description } ) => {

	const result = await XHR.put.bind ( this ) ( {
		url  : `${ configs.USER_API_URL }user-profile/deleteProfile/${ userId }`,
		data : {
			reason,
			description
		}
	} );

	return result;
};
