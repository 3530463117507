import { fetchAndActivate, getValue } from "firebase/remote-config";

import { getRemoteConf } from ".";

const remoteConfig = {
	getData: async ( projectKey, projectParamData ) => {

		let data;

		const remoteConf = getRemoteConf ();
		const config     = remoteConf[projectKey];

		// Caching interval --- right now set to 1 min
		config.settings.minimumFetchIntervalMillis = 60 * 1000; // milliseconds

		await fetchAndActivate ( config );

		data = getValue ( config, projectParamData );

		if ( data["_value"] )
			return JSON.parse ( data["_value"] );

		return null;
	}
};

export default remoteConfig;
